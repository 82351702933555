<template>
  <div>
    <div class="mobile-wrapper">
      <div class="flex flex-col justify-center p-3">
        <div
          class="bg-white text-center items-center rounded-[12px] p-2 flex flex-col space-y-2"
        >
          <div class="text-4xl text-sw-pink">Welcome Back!</div>
          <div class="welcome-note bold flex flex-col w-full space-y-2">
            <div>
              Live The <span class="text-sw-pink">Fabulous</span> Life.
              <span class="text-sw-pink">Fabulous</span> Fashion.
              <span class="text-sw-pink">Fabulous</span> Parties.
              <span class="text-sw-pink">Fabulous</span>
              Friends.
            </div>
            <div class="w-full">
              <router-link
                class="cursor rounded-[10px] shadow-md text-2xl text-center flex items-center justify-center bg-pink-300 py-10 font-extrabold text-white"
                exact
                tag="div"
                to="/game"
              >
                PLAY NOW
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LoggedIn'
}
</script>

<style scoped>
.left {
  text-align: left;
}

.center {
  text-align: center;
}

.bold {
  font-weight: bold;
}

.home-left {
  margin-right: 15px;
  width: 100%;
  display: inline-block;
  vertical-align: top;
}

@media only screen and (min-width: 769px) {
  /* Desktop */
  .img-options {
    cursor: pointer;
    width: 1000px;
  }

  .welcome-block {
    text-align: left;
    background: #d1ebf1
      url("//assets.sororitywars.app/backgrounds/blue-background.jpg") repeat
      center top;
    padding: 10px 5px;
    border: 1px solid #238cbd;
    border-radius: 6px;
    -moz-border-radius: 6px;
    width: 98%;
    display: inline-block;
  }

  .welcome-note {
    color: #000000;
    font-size: 18px;
  }

  .click-to-play {
    font-size: 22px;
  }
}

@media only screen and (max-width: 768px) {
  /* Mobile */
  .img-options {
    margin: 2px auto;
    cursor: pointer;
    width: 98%;
  }

  .welcome-block {
    text-align: left;
    background: #d1ebf1
      url("//assets.sororitywars.app/backgrounds/blue-background.jpg") repeat
      center top;
    padding: 5px;
    border: 1px solid #238cbd;
    border-radius: 6px;
    -moz-border-radius: 6px;
    width: 94%;
    display: inline-block;
  }

  .welcome-note {
    color: #000000;
    font-size: 16px;
  }

  .click-to-play {
    font-size: 18px;
  }
}

.feed-title {
  font-size: 22px;
  border-bottom: 1px solid #000000;
  padding-bottom: 2px;
  margin: 0 0 4px 0;
}

.house-mom-signature {
  font-size: 18px;
}
</style>
