<template>
  <div id="app" class="w-full flex flex-col items-center" @click="fuckBots">
    <div class="flex justify-center w-screen">
      <div class="flex flex-col w-full items-center justify-center px-2 py-4">
        <div class="w-full lg:w-[1080px] gap-2 grid grid-cols-2 lg:grid-cols-5">
          <router-link
            active-class="top-nav-text"
            class="top-nav-text"
            exact
            tag="div"
            to="/"
            >Home
          </router-link>
          <router-link
            v-if="loggedIn"
            active-class="top-nav-text"
            class="top-nav-text"
            tag="div"
            to="/add-train"
            >Add Train
          </router-link>
          <router-link
            active-class="top-nav-text"
            class="top-nav-text"
            tag="div"
            to="/game"
            >Play
          </router-link>
          <router-link
            v-if="!loggedIn"
            active-class="top-nav-text"
            class="top-nav-text"
            tag="div"
            to="/login"
            >Login
          </router-link>
          <router-link
            v-if="!loggedIn"
            active-class="top-nav-text"
            class="top-nav-text"
            tag="div"
            to="/register"
          >
            Register
          </router-link>
          <router-link
            active-class="top-nav-text"
            class="top-nav-text"
            tag="div"
            to="/contact"
            >Contact
          </router-link>
          <span v-if="loggedIn" class="top-nav-text" @click="logout"
            >Logout</span
          >
        </div>
        <div
          v-if="loggedIn"
          class="p-5 mt-[5px] w-full flex flex-col items-center"
        >
          <div
            class="w-full lg:w-[1080px] relative rounded-[8px] overflow-hidden bg-white border border-sw-blue"
          >
            <input
              v-model="loadID"
              class="w-full bg-transparent focus:ring-0 outline-none p-[10px]"
              placeholder="Enter Sister's ID here"
              type="text"
            />
            <div
              class="absolute right-0 inset-y-0 w-[100px] flex items-center justify-center bg-sw-blue"
            >
              <button
                class="w-full h-full font-bold text-white text-[1.15rem]"
                @click="loadPlayer"
              >
                Load
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div style="display: none">
      <span @click="logout">Do Event</span>
      <span @click="logout">Do Again</span>
    </div>
    <recaptcha-two v-if="loginCaptchad" />
    <router-view v-else />
    <div class="notify-placement">
      <NotificationBase
        v-for="notif in notifs"
        :key="notif.id"
        :event="notif"
      />
    </div>
  </div>
</template>
<script>
import NotificationBase from '@/components/NotificationBase'
import RecaptchaTwo from '@/components/RecaptchaTwo'

export default {
  components: { RecaptchaTwo, NotificationBase },
  computed: {
    loggedIn () {
      return this.$store.state.user.loggedIn
    },
    id () {
      return this.$store.state.user.id
    },
    name () {
      return this.$store.state.user.name
    },
    admin () {
      return this.$store.state.user.admin
    },
    loginCaptchad () {
      return this.$store.state.user.loginCaptchad
    }
  },
  watch: {
    loggedIn (val) {
      if (val) {
        this.initNotifChannel()
      } else {
      }
    }
  },
  mounted () {
    this.initNotifChannel()
  },
  data () {
    return {
      notifs: [],
      antiBot: {
        count: 0,
        pixels: ''
      },
      loadID: '',
      routerKey: 1
    }
  },
  methods: {
    initNotifChannel () {
      if (this.loggedIn) {
        this.$store.state.user.echo
          .private(`App.User.${this.id}`)
          .notification((notification) => {
            if (notification.type === 'App\\Notifications\\HealthChange') {
              this.$store.commit('changeHealth', -notification.amount)
            } else {
              if (
                notification.type === 'App\\Notifications\\SororityWarsSlap'
              ) {
                this.$store.commit(
                  'changeHealth',
                  -notification.entry.content.damage
                )
              }
              this.notifs.push(notification)
            }
          })
      }
    },
    loadPlayer () {
      if (
        this.loadID === '' ||
        this.loadID.length < 5 ||
        this.$route.path === '/game/profile/' + this.loadID
      ) {
        return false
      }
      this.$router.push('/game/profile/' + this.loadID.toUpperCase())
      this.loadID = ''
    },
    logout () {
      this.$store.dispatch('logout').then((r) => {
        if (r.success) {
          this.$store.dispatch('modCreate')
          this.$router.push({ name: 'login' })
        }
      })
    },
    fuckBots (e) {
      let pixels = `${e.clientX}|${e.clientY}`
      if (this.antiBot.pixels !== pixels) {
        this.antiBot.pixels = pixels
        this.antiBot.count = 0
      }
      this.antiBot.count += 1
      if (this.antiBot.count >= 1000 && this.antiBot.count === 1000) {
        e.preventDefault()
        e.stopPropagation()
        this.$store.dispatch('logout').then((r) => {
          if (r.success) this.$router.push({ name: 'home' })
        })
      }
    }
  }
}
</script>
<style scoped>
.top-nav-text {
  @apply rounded-[12px] flex justify-center cursor-pointer lg:w-auto text-lg border border-pink-300 bg-white p-2 shadow text-[#f1718b];
}

@media only screen and (min-width: 769px) {
  /* Desktop */
}

//font-size: 25px;
//text-align: center;
//color: #fb3d61 !important;
//border: 1px solid #e84063;
//margin: 0 2px;
//padding: 2px 0;
//display: inline-block;
//cursor: pointer;
//width: 210px;

@media only screen and (max-width: 768px) {
  /* Mobile / Tablet */
}

#app {
  text-align: center;
}
</style>

<style>
@import url("https://fonts.googleapis.com/css?family=Montserrat");
</style>
